import { useTolgee } from "@tolgee/react";
import { pt, ru } from "date-fns/locale";
import { Calendar } from "lucide-react";
import { forwardRef } from "react";
import RDatePicker, { DatePickerProps, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Input } from "@/shared/ui";

// Сюда надо добавлять все необходимые локализации
registerLocale("ru", ru);
registerLocale("pt", pt);

export const DatePicker = forwardRef<RDatePicker, DatePickerProps>(
    ({ value, ...props }, ref) => {
        const tolgee = useTolgee(["language"]);
        const selected = value ? new Date(value) : undefined;

        return (
            <RDatePicker
                ref={ref}
                selected={selected}
                customInput={
                    <Input
                        icon={<Calendar className="w-4 h-4 text-primary" />}
                    />
                }
                placeholderText={tolgee.t("form.date.from.placeholder")}
                dateFormat="Pp"
                locale={tolgee.getLanguage()}
                wrapperClassName="w-full"
                popperClassName="z-[500]"
                {...props}
            />
        );
    },
);

DatePicker.displayName = "DatePicker";
