import { useTranslate } from "@tolgee/react";
import L from "leaflet";
import "leaflet-polylinedecorator";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import Control from "react-leaflet-custom-control";

import { formatDate } from "@/shared/lib/utils";

import { alertIcon, getColor, getDistance } from "../lib";
import { IGpsData } from "../model";
import { Player } from "./player";

const useContent = () => {
    const { t } = useTranslate();

    return (dtime: Date, speed?: number) => {
        return `
            <div>${formatDate(dtime)}</div>
            <div>${speed} ${t("km_h")}</div>
        `;
    };
};

const MIN_DISTANCE = 2; // Минимальное расстояние между точками для отображения линий
const WEIGHT = 2; // Ширина линии

export const Track: React.FC<IGpsData & { withPlayer?: boolean }> = ({
    data,
    withPlayer = false,
}) => {
    const map = useMap();
    const getContent = useContent();
    const { track, events } = data;

    // Определение области видимости карты
    const allCoordinates = useMemo(
        () => L.latLngBounds(track.map((point) => [point.lat, point.lng])),
        [track],
    );

    useEffect(() => {
        // Отображение событий на карте в виде маркеров
        events.forEach((event) => {
            const marker = L.marker([+event.latitude, +event.longitude], {
                icon: alertIcon,
            }).addTo(map);

            marker.bindPopup(`
                <div class="font-bold mb-2">${event.type_name}</div>
                ${getContent(new Date(event.dtime), event.speed)}
            `);
        });

        for (let i = 0; i < track.length - 1; i++) {
            const point1 = track[i];
            const point2 = track[i + 1];
            const color = getColor(point2.speed);
            const distance = getDistance(
                point1.lat,
                point1.lng,
                point2.lat,
                point2.lng,
            );

            // Создание линий Polyline с разным цветом в зависимости от скорости
            const polyline = L.polyline(
                [
                    [point1.lat, point1.lng],
                    [point2.lat, point2.lng],
                ],
                {
                    color,
                    opacity: distance < MIN_DISTANCE ? 1 : 0, // Прозрачность для линий с расстоянием меньше чем минимальное
                    weight: WEIGHT,
                },
            ).addTo(map);

            // Открытие подсказки при клике на линию
            polyline.bindTooltip(
                getContent(new Date(point2.timeIso), point2.speed),
            );

            L.polylineDecorator(polyline, {
                patterns: [
                    ...(distance > MIN_DISTANCE
                        ? [
                              // Добавление штрихов на линию если расстояние больше чем минимальное значение
                              {
                                  offset: 0,
                                  repeat: 15, // Расстояние между штрихами
                                  symbol: L.Symbol.dash({
                                      pixelSize: 7,
                                      pathOptions: {
                                          color: "gray",
                                          weight: WEIGHT,
                                      },
                                  }), // Штрихи 10px длиной
                              },
                          ]
                        : [
                              // Добавление стрелок на линию
                              {
                                  offset: "100%",
                                  repeat: 0,
                                  symbol: L.Symbol.arrowHead({
                                      pixelSize: 7,
                                      polygon: false,
                                      pathOptions: {
                                          color,
                                          stroke: true,
                                          weight: WEIGHT,
                                      },
                                  }),
                              },
                          ]),
                ],
            }).addTo(map);
        }

        map.fitBounds(allCoordinates, { padding: [50, 50] });
    }, [allCoordinates, events, getContent, map, track]);

    // const geoJsonLayer_black = useMemo(() => {
    //     return L.geoJSON(data, {
    //         style: (feature) => {
    //             const coords = feature.geometry.coordinates;
    //             const distance = getDistance(
    //                 coords[0][1],
    //                 coords[0][0],
    //                 coords[1][1],
    //                 coords[1][0],
    //             );
    //
    //             if (distance > 2) {
    //                 return {
    //                     color: "black",
    //                     dashArray: [8, 8],
    //                 };
    //             }
    //             return {
    //                 color: "black",
    //                 weight: 4,
    //             };
    //         },
    //     });
    // }, [data]);
    //
    // const geoJsonLayer = useMemo(() => {
    //     return L.geoJSON(data, {
    //         onEachFeature: (feature, layer) => {
    //             // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //             layer.bindPopup(popup(feature));
    //         },
    //         pointToLayer: (_feature, latlng) => {
    //             return L.marker(latlng, {
    //                 icon: alertIcon,
    //                 autoPanOnFocus: true,
    //             });
    //         },
    //         style: (feature) => {
    //             const speed = (feature.properties.speed ?? 0) as number;
    //             const coords = feature.geometry.coordinates;
    //             const distance = getDistance(
    //                 coords[0][1],
    //                 coords[0][0],
    //                 coords[1][1],
    //                 coords[1][0],
    //             );
    //
    //             if (distance > 2) {
    //                 return {
    //                     color: "black",
    //                     dashArray: [8, 8],
    //                 };
    //             }
    //
    //             return {
    //                 color: getColor(speed),
    //                 weight: 2,
    //             };
    //         },
    //     });
    // }, [data]);
    //
    // useEffect(() => {
    //     map.addLayer(geoJsonLayer_black);
    //     map.addLayer(geoJsonLayer);
    //     map.fitBounds(geoJsonLayer.getBounds(), { padding: [50, 50] });
    //
    //     return () => {
    //         map.removeLayer(geoJsonLayer_black);
    //         map.removeLayer(geoJsonLayer);
    //     };
    // }, [geoJsonLayer, geoJsonLayer_black, map]);

    return withPlayer ? (
        <Control position="bottomleft">
            <Player data={track} />
        </Control>
    ) : null;
};
