import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslate } from "@tolgee/react";
import { subMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { FilterField } from "@/features";

import { usePostDevicesByIdArchiveVideoMutation } from "@/shared/api/general.api";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    DialogFooter,
    Form,
    WrappedSelect,
    toast,
} from "@/shared/ui";

const CURR_DATETIME = new Date();

export const Archive: React.FC = () => {
    const { t } = useTranslate();
    const { id } = useParams();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [post, { isLoading, isSuccess, isError }] =
        usePostDevicesByIdArchiveVideoMutation();

    const zodDateSchema = z
        .union([
            z.string({
                message: t("required_error"),
            }),
            z.date({
                message: t("invalid_datetime_error"),
            }),
        ])
        .transform((date) => new Date(date).toISOString());

    const zodSchema = z.object({
        date_from: zodDateSchema,
        date_to: zodDateSchema,
        channel: z
            .string({
                message: t("required_error"),
            })
            .array(),
    });

    const form = useForm<z.infer<typeof zodSchema>>({
        resolver: zodResolver(zodSchema),
        defaultValues: {
            date_from: subMinutes(CURR_DATETIME, 5).toISOString(),
            date_to: CURR_DATETIME.toISOString(),
        },
    });

    const onSubmit: SubmitHandler<z.infer<typeof zodSchema>> = async (
        values,
    ) => {
        if (id) {
            await post({
                id: id,
                body: values,
            });
        }
    };

    const handleModalOpen = (open: boolean) => () => {
        setModalIsOpen(open);
    };

    useEffect(() => {
        if (isSuccess) {
            form.reset();
            handleModalOpen(true)();
        }
    }, [form, isSuccess]);

    if (isError) {
        toast({
            title: t("error.fetch"),
            description: t("error.fetch_video"),
            variant: "destructive",
        });
    }

    return (
        <>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="grid gap-4"
                >
                    <div className="grid grid-cols-2 gap-8">
                        <FilterField
                            name="channel"
                            label={t("form.channel.label")}
                        >
                            <WrappedSelect
                                showSearch
                                placeholder={t("form.channel.placeholder")}
                                mode="multiple"
                                options={[
                                    { id: "1", name: "1" },
                                    { id: "2", name: "2" },
                                    { id: "3", name: "3" },
                                ]}
                                fieldNames={{
                                    label: "name",
                                    value: "id",
                                }}
                            />
                        </FilterField>
                        <div className="grid grid-cols-2 gap-4">
                            <FilterField
                                name="date_from"
                                label={t("form.date.from.label")}
                            >
                                <DatePicker showTimeInput />
                            </FilterField>
                            <FilterField
                                name="date_to"
                                label={t("form.date.to.label")}
                            >
                                <DatePicker showTimeInput />
                            </FilterField>
                        </div>
                    </div>
                    <div className="flex justify-between space-x-2 mt-5">
                        <div></div>
                        <Button type="submit" disabled={isLoading}>
                            {t("button.apply")}
                        </Button>
                    </div>
                </form>
            </Form>
            <Dialog open={modalIsOpen} onOpenChange={handleModalOpen(false)}>
                <DialogContent className="p-10">
                    <div className="text-center mb-10 mt-[80px]">
                        <h3 className="mb-4 text-3xl">
                            {t("device_video_creating.title")}
                        </h3>
                        <p>{t("device_video_creating.p1")}</p>
                        <p>{t("device_video_creating.p2")}</p>
                        <p>
                            {t("device_video_creating.p3")}&nbsp;
                            <a
                                className="text-primary text-underline hover:underline underline-offset-4"
                                href="#"
                            >
                                {t("nav.user.reports")}
                            </a>
                        </p>
                    </div>
                    <DialogFooter className="sm:justify-center">
                        <Button onClick={handleModalOpen(false)}>
                            {t("button.got_it")}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};
