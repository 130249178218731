import { useTolgee, useTranslate } from "@tolgee/react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { PropsWithChildren, memo } from "react";
import { LayersControl, MapContainerProps, TileLayer } from "react-leaflet";
import { MapContainer } from "react-leaflet/MapContainer";

import { cn } from "@/shared/lib/utils";

interface TileLayerOptions extends L.TileLayerOptions {
    url: string;
    name: string;
    checked?: boolean;
    className?: string;
}

export const Map: React.FC<PropsWithChildren<MapContainerProps>> = memo(
    ({ children, className, ...props }) => {
        const { t } = useTranslate();
        const tolgee = useTolgee(["language"]);
        const lang = tolgee.getLanguage();

        const TILES: TileLayerOptions[] = [
            {
                id: "yandex",
                name: t("yandex_map"),
                url: `https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=24.07.11-0-b240606123130&x={x}&y={y}&z={z}&scale=2&projection=web_mercator&lang=${lang}`,
                checked: true,
            },
            {
                id: "google",
                name: "Google Maps",
                url: `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=${lang}&scale=2`,
            },
            {
                id: "2gis",
                name: "2GIS",
                url: "http://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}",
            },
            {
                id: "osm",
                name: "OpenStreetMap",
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            },
        ];

        return (
            <MapContainer
                zoomAnimation={true}
                attributionControl={false}
                renderer={L.canvas()}
                className={cn(
                    "h-[500px] w-full rounded-xl overflow-hidden",
                    className,
                )}
                {...props}
            >
                <LayersControl position="topright">
                    {TILES.map(({ id, name, checked, ...restTileProps }) => (
                        <LayersControl.BaseLayer
                            key={id}
                            name={name}
                            checked={checked}
                        >
                            <TileLayer {...restTileProps} />
                        </LayersControl.BaseLayer>
                    ))}
                </LayersControl>
                {children}
            </MapContainer>
        );
    },
);

Map.displayName = "Map";
