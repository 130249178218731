import { useTranslate } from "@tolgee/react";
import L from "leaflet";
import { Copy, SquareArrowOutUpRight } from "lucide-react";
import { Marker } from "react-leaflet";

import { Map } from "@/features";
import { EventModalTab, EventVideo } from "@/features/event";

import { useGetEventsByIdQuery } from "@/shared/api/general.api";
import { useEventType } from "@/shared/lib/hooks";
import { formatDate } from "@/shared/lib/utils";
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from "@/shared/ui";

import { Figure } from "./figure";

interface EventScreenProps {
    eventId: number;
    defaultTab?: EventModalTab;
}

export const EventScreen: React.FC<EventScreenProps> = ({
    eventId,
    defaultTab = EventModalTab.Photo,
}) => {
    const { t } = useTranslate();
    const { data } = useGetEventsByIdQuery(eventId);
    const { getEventTypeBySlug } = useEventType();

    if (!data) return null;

    const {
        id,
        created_at,
        received_at,
        speed,
        vehicle,
        // driver,
        device,
        client,
        photo,
        video,
        latitude,
        longitude,
        type,
    } = data;

    const hasVideo = video.length > 0;
    const hasCoordinates = latitude && longitude;
    const eventName = getEventTypeBySlug(type).name;

    const onClipboardCopy = () => {
        void navigator.clipboard.writeText(window.location.href);
    };

    return (
        <>
            <div className="grid grid-cols-[3fr_4fr] gap-x-10">
                <div className="flex flex-col gap-8">
                    <div>
                        <span
                            onClick={onClipboardCopy}
                            className="text-primary hover:underline underline-offset-4 cursor-pointer"
                            title={t("events.copy_link")}
                        >
                            {t("event.title")}&nbsp;№{id}
                            <Copy className="inline-block w-4 h-4 ml-2" />
                        </span>
                        <h2 className="text-3xl font-bold">{eventName}</h2>
                    </div>
                    <div className="grid grid-cols-2 gap-x-8">
                        <Figure
                            label={t("created_at_date")}
                            content={formatDate(new Date(created_at))}
                        />
                        <Figure
                            label={t("received_at_date")}
                            content={formatDate(new Date(received_at))}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-x-8">
                        <div className="flex justify-center items-center bg-accent rounded-2xl">
                            <span className="text-3xl font-bold">
                                {speed}&nbsp;{t("km_h")}
                            </span>
                        </div>
                        <div className="flex flex-col gap-6">
                            <Figure
                                label={t("registration_number")}
                                content={vehicle?.registration_number}
                            />
                            <Figure
                                label={t("coordinates")}
                                content={`${latitude}, ${longitude}`}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-8">
                        {/*<Figure*/}
                        {/*    label={t("tabelnyi_nomer_voditelya")}*/}
                        {/*    content={driver?.name}*/}
                        {/*/>*/}
                        <div></div>
                        <Figure
                            label={t("side_number")}
                            content={vehicle?.side_number}
                        />
                    </div>
                    <div className="flex gap-x-4">
                        <a
                            href={`/devices/${device.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="w-full"
                        >
                            <Button variant="outline" className="w-full">
                                {t("device")}
                                <SquareArrowOutUpRight className="inline-block w-4  ml-2" />
                            </Button>
                        </a>
                        {/*<a*/}
                        {/*    href={`/drivers/${driver?.id}`}*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noreferrer"*/}
                        {/*    className="w-full"*/}
                        {/*>*/}
                        {/*    <Button variant="outline" className="w-full">*/}
                        {/*        {t("driver")}*/}
                        {/*        <SquareArrowOutUpRight className="inline-block w-4  ml-2" />*/}
                        {/*    </Button>*/}
                        {/*</a>*/}
                    </div>
                </div>
                <div>
                    <Tabs defaultValue={defaultTab}>
                        <TabsList className="w-full">
                            <TabsTrigger value="photo" className="w-full">
                                {t("button.photo")}
                            </TabsTrigger>
                            {hasVideo && (
                                <TabsTrigger value="video" className="w-full">
                                    {t("button.video")}
                                </TabsTrigger>
                            )}
                            {hasCoordinates && (
                                <TabsTrigger value="map" className="w-full">
                                    {t("button.map")}
                                </TabsTrigger>
                            )}
                        </TabsList>
                        <TabsContent value="photo">
                            {/* TODO: move to Image component */}
                            <img
                                src={
                                    photo ??
                                    `https://placehold.co/640x480?text=${t("event-photo-not-found")}`
                                }
                                onError={(e) => {
                                    e.currentTarget.src = `https://placehold.co/640x480?text=${t("event-photo-not-found")}`;
                                }}
                                alt="event-photo"
                                className="w-full rounded-2xl"
                            />
                        </TabsContent>
                        {hasVideo && (
                            <TabsContent value="video">
                                <EventVideo data={video} />
                            </TabsContent>
                        )}
                        {hasCoordinates && (
                            <TabsContent value="map">
                                <Map center={[+latitude, +longitude]} zoom={15}>
                                    <Marker
                                        position={[+latitude, +longitude]}
                                        icon={L.icon({
                                            iconUrl: "/assets/icons/alert.svg",
                                            iconSize: [40, 40],
                                            iconAnchor: [20, 40],
                                            popupAnchor: [0, -32],
                                        })}
                                    />
                                </Map>
                            </TabsContent>
                        )}
                    </Tabs>
                </div>
            </div>
            <div className="grid grid-cols-[3fr_4fr] gap-x-10">
                <div>
                    <div className="flex gap-x-4 justify-between items-center">
                        <div>
                            <h3>{client.name}</h3>
                            {/* TODO: add org structure */}
                            {/* <p>Орг структуры</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
