/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("token") ?? sessionStorage.getItem("token");
const lang =
    localStorage.getItem("__tolgee_currentLanguage") ??
    import.meta.env.VITE_APP_DEFAULT_LOCALE;

export const axiosBaseQuery = (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
): BaseQueryFn<
    {
        url: string;
        method?: AxiosRequestConfig["method"];
        body?: AxiosRequestConfig["data"];
        params?: AxiosRequestConfig["params"];
        headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
> => {
    return async ({ url, method, body, params, headers }) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data: body,
                params,
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Content-Language": lang,
                },
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;

            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data ?? err.message,
                },
            };
        }
    };
};
