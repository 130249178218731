import { getCoreRowModel } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";
import Cookies from "js-cookie";
import {
    ChevronLeft,
    ChevronRight,
    StretchHorizontal,
    StretchVertical,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { Loading } from "@/features";

import { GetEventsApiResponse } from "@/shared/api/general.api";
import { cn } from "@/shared/lib/utils";
import { DataTable, Pagination, PaginationEllipsis } from "@/shared/ui";

import { EventCard, EventModal, EventModalProps } from "../index";
import { useColumns } from "./use-columns";

type ViewType = "table" | "cards";

interface EventDataProps {
    data?: GetEventsApiResponse;
    isLoading?: boolean;
    onPaginationChange: (page: number) => void;
}

const VIEW_COOKIE_NAME = "events-view";
const TOGGLE_VIEW_BTN_CLASSNAMES = [
    "flex",
    "items-center",
    "justify-center",
    "w-8",
    "h-8",
    "cursor-pointer",
    "rounded-md",
    "py-1",
    "text-sm",
    "hover:bg-black/10",
    "data-[active='true']:bg-black",
    "data-[active='true']:text-white",
    "transition-colors",
];

export const EventDataList: React.FC<EventDataProps> = ({
    data,
    isLoading,
    onPaginationChange,
}) => {
    const { t } = useTranslate();
    const defaultView = Cookies.get(VIEW_COOKIE_NAME) ?? "cards";
    const [view, setView] = useState<ViewType>(defaultView as ViewType);
    const [modal, setModal] = useState<EventModalProps>();

    const toggleOpen = useCallback((event: EventModalProps) => {
        setModal(event);
    }, []);

    const columns = useColumns({ toggleOpen });

    const onChangeView = (view: ViewType) => () => {
        setView(view);
    };

    useEffect(() => {
        Cookies.set(VIEW_COOKIE_NAME, view);
    }, [view]);

    if (!data) return null;

    return (
        <>
            <div className="flex items-center justify-between">
                <h3>
                    {t("found")}&nbsp;
                    {t("events.title")}
                    &nbsp;
                    {data.total}
                </h3>
                <div className="flex items-center gap-1.5 bg-secondary p-1.5 rounded-lg">
                    <div
                        onClick={onChangeView("cards")}
                        className={cn(TOGGLE_VIEW_BTN_CLASSNAMES)}
                        data-active={view === "cards"}
                    >
                        <StretchVertical className="w-4 h-4" />
                    </div>
                    <div
                        onClick={onChangeView("table")}
                        className={cn(TOGGLE_VIEW_BTN_CLASSNAMES)}
                        data-active={view === "table"}
                    >
                        <StretchHorizontal className="w-4 h-4" />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {view === "table" && (
                        <DataTable
                            columns={columns}
                            data={data.data}
                            getCoreRowModel={getCoreRowModel()}
                            state={{ pagination: undefined }}
                        />
                    )}
                    {view === "cards" && (
                        <div className="grid grid-cols-4 gap-5 mt-4">
                            {data?.data.map((item, index) => (
                                <EventCard
                                    key={index}
                                    toggleOpen={toggleOpen}
                                    {...item}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}
            <Pagination className="justify-start mt-10">
                <ReactPaginate
                    forcePage={data.current_page - 1}
                    containerClassName="flex flex-row items-center gap-1"
                    disableInitialCallback
                    disabledClassName="opacity-50 pointer-events-none"
                    breakLabel={<PaginationEllipsis />}
                    nextLabel={<ChevronRight className="h-4 w-4" />}
                    nextLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm w-10 h-10"
                    hrefBuilder={(page) => `?page=${page}`}
                    pageLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm min-w-10 h-10 border-none"
                    activeLinkClassName="bg-primary text-primary-foreground hover:text-primary-foreground"
                    onPageChange={({ selected }) => {
                        onPaginationChange(selected + 1);
                    }}
                    pageRangeDisplayed={3}
                    pageCount={data.total}
                    previousLabel={<ChevronLeft className="h-4 w-4" />}
                    previousLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm  w-10 h-10"
                    renderOnZeroPageCount={null}
                />
            </Pagination>
            <EventModal
                id={modal?.id}
                tab={modal?.tab}
                toggleOpen={toggleOpen}
            />
        </>
    );
};
