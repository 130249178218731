// import { ChevronDown } from "lucide-react";
import { useTranslate } from "@tolgee/react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FilterField } from "@/features";

import { GetEventsApiArg } from "@/shared/api/general.api";
import { Button, DatePicker, Form, Input } from "@/shared/ui";

export const Filter: React.FC<{
    values?: GetEventsApiArg;
    onSubmit: SubmitHandler<GetEventsApiArg>;
}> = ({ values, onSubmit }) => {
    const { t } = useTranslate();
    const form = useForm<GetEventsApiArg>();

    useEffect(() => {
        form.reset(values);
    }, [form, values]);

    const onSubmitHandler: SubmitHandler<GetEventsApiArg> = (data) => {
        onSubmit(data);
    };

    const onResetHandler = () => {
        onSubmit({});
        form.reset({});
    };

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmitHandler)}
                className="grid gap-4"
            >
                <div className="grid grid-cols-2 gap-8">
                    <FilterField
                        name="filter.driver_uid"
                        label={t("form.uid.label")}
                    >
                        <Input placeholder={t("form.uid.placeholder")} />
                    </FilterField>
                    <div className="grid grid-cols-2 gap-4">
                        <FilterField
                            name="filter.date_from"
                            label={t("form.date.from.label")}
                        >
                            <DatePicker showTimeInput />
                        </FilterField>
                        <FilterField
                            name="filter.date_to"
                            label={t("form.date.to.label")}
                        >
                            <DatePicker showTimeInput />
                        </FilterField>
                        {/*<div className="grid grid-cols-3 gap-2">*/}
                        {/*<FilterField*/}
                        {/*    name="filter.date_from"*/}
                        {/*    label="Начало периода"*/}
                        {/*    className="col-span-2"*/}
                        {/*>*/}
                        {/*    <DatePicker />*/}
                        {/*</FilterField>*/}
                        {/*<FilterField name="filter.date_from_time">*/}
                        {/*    <Input type="time" />*/}
                        {/*</FilterField>*/}
                        {/*</div>*/}
                        {/*<div className="grid grid-cols-3 gap-2">*/}
                        {/*    <FilterField*/}
                        {/*        name="filter.date_to"*/}
                        {/*        label="Конец периода"*/}
                        {/*        className="col-span-2"*/}
                        {/*    >*/}
                        {/*        <DatePicker />*/}
                        {/*    </FilterField>*/}
                        {/*    <FilterField name="filter.date_to_time">*/}
                        {/*        <Input type="time" />*/}
                        {/*    </FilterField>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="flex justify-between space-x-2 mt-5">
                    <div>
                        {/*<Button type="button" variant="outline" disabled>*/}
                        {/*    {t("button.export_report")}*/}
                        {/*    <ChevronDown className="ml-1 h-4 w-4" />*/}
                        {/*</Button>*/}
                    </div>
                    <div className="flex space-x-2">
                        <Button type="submit">{t("button.apply")}</Button>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onResetHandler}
                        >
                            {t("button.reset")}
                        </Button>
                    </div>
                </div>
            </form>
        </Form>
    );
};
