import { type ClassValue, clsx } from "clsx";
import { format } from "date-fns";
import { pt, ru } from "date-fns/locale";
import { twMerge } from "tailwind-merge";

const lang =
    localStorage.getItem("__tolgee_currentLanguage") ??
    import.meta.env.VITE_APP_DEFAULT_LOCALE;

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function formatDate(date: Date, formatStr = "PP pp") {
    return format(date, formatStr, {
        locale: { ru, pt }[lang],
    });
}
